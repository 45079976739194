import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { ReactComponent as CaretRight } from '../images/icon-caret-right.svg'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'
import Text from './text'
import Button from './button'
import Link from './link'
import { global } from '../styles/global'
import { saveAs } from 'file-saver'
import { xs } from '../utils/breakpoints'

const NavButton = ({ enabled, onClick, reversed }) => (
  <button
    css={css`
      ${tw`flex items-center justify-center w-12 h-12`}
      ${reversed && tw`transform rotate-180`}
      svg {
        ${tw`transition transition-transform duration-300`}
        path {
          ${tw`text-opacity-100 fill-current text-primary-500`}
        }
      }
      &:hover {
        svg {
          ${tw`transform translate-x-1`}
        }
      }
      &:disabled {
        ${tw`pointer-events-none`}
        path {
          ${tw`text-opacity-40`}
        }
      }
    `}
    type="button"
    onClick={onClick}
    disabled={!enabled}
  >
    <CaretRight />
  </button>
)

NavButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  reversed: PropTypes.bool,
}

const CardCarousel = ({ cards, swipeLabel, style }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: false,
    speed: 5,
    align: 'start',
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex])

  const fileDownload = (event, link, label) => {
    saveAs(link, label)
    event.preventDefault()
  }

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)
  }, [embla, onSelect])

  return (
    <div>
      <div
        css={[
          css`
            ${tw`block overflow-visible`}
            &.is-draggable {
              ${tw`cursor-grab`}
            }
            &.is-dragging {
              ${tw`cursor-grabbing`}
            }
          `,
        ]}
        ref={viewportRef}
      >
        <div css={[tw`flex flex-row flex-nowrap`, style]}>
          {cards.map((card, i) => (
            <div
              key={i}
              css={[
                tw`flex flex-col items-start flex-none px-6 pt-14 pb-32 mr-6 w-80
                lg:(px-10 pt-16 pb-20 ml-8 mr-0 w-116)`,
                i % 2 !== 0 ? tw`bg-secondary-920` : tw`bg-white`,
              ]}
            >
              {card.image !== null ? (
                <div css={tw`mb-9`}>
                  <Image image={card.image} style={tw`w-18 h-18`} />
                </div>
              ) : (
                <div css={tw`w-full bg-transparent pt-4`}>
                  <Heading
                    content={card.callToAction.label}
                    headingType="h4"
                    style={tw`leading-8 font-bold lg:text-3xl`}
                  />
                  <div css={tw`mt-6`}>
                    {card.callToAction.subLinks !== null &&
                      card.callToAction.subLinks.map((link, index) => (
                        <div
                          css={tw`border-b border-grey-very-light w-48 pt-2 sm:w-64 lg:(pt-3.5 w-96)`}
                          key={index}
                        >
                          <Heading
                            content={link.label}
                            headingType="h6"
                            style={tw`text-base leading-7 font-medium text-secondary-910 mt-4 mb-2 sm:text-lg lg:(text-xl)`}
                          />
                          {/* <Link
                            onClick={(event) => fileDownload(event, link.link, link.description)}
                            css={tw`flex flex-row`}
                          >
                            <Text
                              content={link.description}
                              style={tw`underline leading-4 text-sm text-secondary-910 pb-2.5 lg:(text-sm pb-6)`}
                            />
                          </Link> */}
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {card.image !== null ? (
                <Heading
                  content={card.titleNode}
                  headingType="h3"
                  style={tw`mb-10 h-32 lg:(mb-16 h-24)`}
                />
              ) : (
                <Text
                  content={card.titleNode}
                  style={tw`leading-4 text-sm text-secondary-910 pt-5 pb-2.5 lg:(text-sm pb-6)`}
                />
              )}
              <div
                css={[
                  css`
                    ${xs} {
                      height: calc(593px + (433 - 593) * ((100vw - 320px) / 93));
                    }
                  `,
                  card.image == null &&
                    css`
                      ${xs} {
                        height: calc(512px + (384 - 512) * ((100vw - 320px) / 93));
                      }
                    `,
                  tw`flex flex-col justify-between items-start sm:h-102 lg:h-84`,
                  card.image == null && tw`justify-end sm:h-94 lg:(h-66 justify-start)`,
                ]}
              >
                {(card.points || []).map((point, index) =>
                  card.image !== null ? (
                    <Text
                      key={index}
                      content={point}
                      style={[
                        tw`py-4 text-primary-500 text-base font-normal leading-6 whitespace-pre-line`,
                      ]}
                    />
                  ) : (
                    <Heading
                      key={index}
                      content={point}
                      headingType="h4"
                      style={tw`pt-20 pb-4 leading-8 font-bold lg:text-3xl`}
                    />
                  )
                )}
                {card.callToAction && card.image !== null ? (
                  <div style={tw`gap-0`}>
                    <Link
                      to={card.callToAction.link}
                      target="_blank"
                      localized={false}
                      /* onClick={(event) =>
                        fileDownload(
                          event,
                          card.callToAction.link,
                          card.callToAction.document.filename
                        )
                      } */
                      css={tw`flex flex-row`}
                    >
                      <Text
                        content={card.callToAction.label}
                        textType="body-md"
                        style={tw`underline text-primary-500 font-normal`}
                      />
                    </Link>
                    {card.callToActionSecond && (
                      <Link
                        to={card.callToActionSecond.link}
                        target="_blank"
                        localized={false}
                        /* onClick={(event) =>
                        fileDownload(
                          event,
                          card.callToAction.link,
                          card.callToAction.document.filename
                        )
                      } */
                        css={tw`flex flex-row`}
                      >
                        <Text
                          content={card.callToActionSecond.label}
                          textType="body-md"
                          style={tw`underline text-primary-500 font-normal`}
                        />
                      </Link>
                    )}
                  </div>
                ) : (
                  <Text
                    content={card.subtitleNode}
                    textType="body-md"
                    style={[
                      tw`text-primary-500 font-normal`,
                      card.image == null && tw`pt-1 pb-8 text-primary-500 font-normal`,
                    ]}
                  />
                )}
                {/* {card.image == null && <div css={tw`relative flex items-start`}>
                  <Button
                    type="primary"
                    size="sm"
                    style={tw`lg:flex`}
                    theme="cyan-yellow"
                    label={card.callToAction.description}
                    link={card.callToAction.link}
                    disabled={card.callToAction.disabled}
                  />
                </div>} */}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        css={tw`relative flex flex-row items-center w-full mt-8 pr-container-mobile
        lg:(justify-start pr-0 mt-20)`}
      >
        <div css={tw`hidden lg:flex flex-row items-center`}>
          <NavButton onClick={scrollPrev} enabled={prevBtnEnabled} reversed />
          <NavButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
        <div
          css={tw`flex-1 bg-primary-500 bg-opacity-10 h-0.5 overflow-hidden relative w-1/2 lg:(flex-none w-1/2 ml-12)`}
        >
          <div
            css={css`
              ${tw`absolute inset-0 h-full transition-all duration-300 ease-in bg-primary-500`}
              width: calc(100% / ${cards.length});
              left: ${selectedIndex * (100 / cards.length)}%;
            `}
          />
        </div>
        <Text
          style={[
            global`typography.subtitle`,
            tw`motion-safe:hover:scale-110 text-primary-300 ml-4`,
          ]}
          content={swipeLabel}
        />
      </div>
    </div>
  )
}

CardCarousel.defaultProps = {
  cards: [],
}

CardCarousel.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
  swipeLabel: PropTypes.string,
  style: StyleType,
}

export default CardCarousel
