import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { ReactComponent as CaretRight } from '../images/icon-caret-right.svg'
import { max, min } from '../utils/breakpoints'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'
import Text from './text'
import Button from './button'
import { global } from '../styles/global'
import Link from './link'
import { saveAs } from 'file-saver'

const NavButton = ({ enabled, onClick, reversed }) => (
  <button
    css={css`
      ${tw`flex items-center justify-center w-12 h-12`}
      ${reversed && tw`transform rotate-180`}
      svg {
        ${tw`transition transition-transform duration-300`}
        path {
          ${tw`text-opacity-100 fill-current text-primary-500`}
        }
      }
      &:hover {
        svg {
          ${tw`transform translate-x-1`}
        }
      }
      &:disabled {
        ${tw`pointer-events-none`}
        path {
          ${tw`text-opacity-40`}
        }
      }
    `}
    type="button"
    onClick={onClick}
    disabled={!enabled}
  >
    <CaretRight />
  </button>
)

NavButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  reversed: PropTypes.bool,
}

const HoverCardCarousel = ({ cards, sliderLabel, style, cardStyle }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: false,
    speed: 5,
    align: 'start',
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)
  }, [embla, onSelect])

  const fileDownload = (event, link, label) => {
    saveAs(link, label)
    event.preventDefault()
  }

  return (
    <div>
      <div
        css={[
          css`
            ${tw`block overflow-visible`}
            &.is-draggable {
              ${tw`cursor-grab`}
            }
            &.is-dragging {
              ${tw`cursor-grabbing`}
            }
          `,
        ]}
        ref={viewportRef}
      >
        <div css={[tw`relative flex flex-row flex-nowrap`, style]}>
          {cards.map((card, i) => (
            <div
              key={i}
              css={[
                css`
                  ${tw`relative flex-none w-80 h-94 mr-6 lg:(h-120 w-120 mr-12)`}/* ${min.lg} {
                    h4 + div {
                      ${tw`overflow-hidden max-h-0`}
                    }
                    :hover {
                      h4 + div {
                        ${tw`max-h-76`}
                      }
                    }
                  } */
                `,
                cardStyle,
              ]}
            >
              {card.image !== null ? (
                <Image
                  image={card.image}
                  imageMobile={card.imageMobile}
                  style={tw`w-full h-full`}
                />
              ) : (
                <div css={tw`w-full h-full bg-primary-50 pt-8 pb-6 px-6 lg:(px-8 pt-12 pb-8) `}>
                  <Heading
                    content={card.callToAction.label}
                    headingType="h4"
                    style={tw`leading-8 font-bold lg:text-3xl`}
                  />
                  <div css={tw`mt-1`}>
                    {card.callToAction.subLinks !== null &&
                      card.callToAction.subLinks.map((link, index) => (
                        <div
                          css={tw`border-b border-grey-very-light w-60 pt-2 sm:w-64 lg:(pt-3.5 w-96)`}
                          key={index}
                        >
                          <Heading
                            content={link.label}
                            headingType="h6"
                            style={tw`leading-7 font-medium text-secondary-910 lg:(text-xl)`}
                          />
                          {(link.description == 'Get quote' || link.description == '獲取報價') ?
                          <div css={tw`relative flex flex-col items-start`}>
                            <Button
                              type="primary"
                              size="sm"
                              style={tw`mb-2.5 lg:(mt-1 mb-6)`}
                              theme="cyan-yellow"
                              label={link.description}
                              link={link.link}
                              disabled={card.callToAction.disabled}
                            />
                            </div>
                            :
                            <Link
                              to={link.link}
                              //onClick={(event) => fileDownload(event, link.link, link.label)}
                              css={tw`flex flex-row`}
                              localized={false}
                              target="_blank"
                              targetBlank={true}
                            >
                              <Text
                                content={link.description}
                                style={tw`underline leading-4 text-sm text-secondary-910 pb-2.5 lg:(text-sm pb-6)`}
                              />
                            </Link>
                          }
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {card.titleNode.childMarkdownRemark.html.length > 0 && <div css={tw`relative mt-12 mb-12 w-72 sm:w-78 lg:(w-120)`}>
                <Heading content={card.titleNode} headingType="h4" />
                <Text
                  content={card.subtitleNode}
                  textType="body-base"
                  style={[
                    tw`mt-4 font-normal h-36 text-base text-primary-500   
                    lg:(text-xl h-46)`,
                    card.titleNode.childMarkdownRemark
                      .html.length > 41 && tw`mt-1.5 font-normal h-30 text-base text-primary-500   
                    lg:(mt-4 text-xl h-46)`,
                    css`
                      a {
                        color: #00bac7;
                        line-height: 28px;
                        display: inline-block;
                        font-family: Roboto;
                        font-size: 20px;
                        font-weight: 400;
                        letter-spacing: 0.005em;
                        text-decoration: underline;
                        position: inherit;
                      }
                      a:after {
                        display: inline-block;
                        border: none !important;
                        transition: none;
                        background-color: #fff;
                      }
                      a:active {
                        position: relative;
                      }
                      p a:hover::before {
                        content: attr(title);
                        position: absolute;
                        width: 90%;
                        left: 5%;
                        padding: 10px;
                        background: white;
                        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
                        z-index: 999999;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #1a1a1a;
                      }
                      p a:active::before {
                        content: '';
                      }

                      ${min.lg} {
                        a:hover::before {
                          margin-top: 30px;
                        }
                      }
                    `,
                  ]}
                />
                <div css={tw`relative flex flex-col items-start`}>
                  <Button
                    type="primary"
                    size="sm"
                    style={tw`mt-10 lg:flex`}
                    theme="cyan-yellow"
                    label={card.callToAction.label}
                    link={card.callToAction.link}
                    disabled={card.callToAction.disabled}
                  />
                  {card.callToActionSecond && (
                    <Link
                      to={card.callToActionSecond.link}
                      target="_blank"
                      localized={false}
                      css={tw`flex flex-row my-6`}
                    >
                      <Text
                        content={card.callToActionSecond.label}
                        textType="body-md"
                        style={tw`underline text-primary-500 font-normal`}
                      />
                    </Link>
                  )}
                </div>
              </div>}
            </div>
          ))}
        </div>
      </div>
      <div
        css={tw`relative flex flex-row items-center w-full mt-116 pr-container-mobile
        lg:(justify-start pr-0 mt-120)`}
      >
        <div css={tw`hidden lg:flex flex-row items-center`}>
          <NavButton onClick={scrollPrev} enabled={prevBtnEnabled} reversed />
          <NavButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
        <div
          css={tw`flex-1 bg-primary-500 bg-opacity-10 h-0.5 overflow-hidden relative w-1/2 lg:(flex-none w-1/2 ml-12)`}
        >
          <div
            css={css`
              ${tw`absolute inset-0 h-full transition-all duration-300 ease-in bg-primary-500`}
              width: calc(100% / ${cards.length});
              left: ${selectedIndex * (100 / cards.length)}%;
            `}
          />
        </div>
        <Text
          style={[
            global`typography.subtitle`,
            tw`motion-safe:hover:scale-110 text-primary-300 ml-4`,
          ]}
          content={sliderLabel}
        />
      </div>
    </div>
  )
}

HoverCardCarousel.defaultProps = {
  cards: [],
}

HoverCardCarousel.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
  sliderLabel: PropTypes.string,
  style: StyleType,
  cardStyle: StyleType,
}

export default HoverCardCarousel
